import React from 'react'
import { NavLink } from 'react-router-dom'
import { FaEnvelope, FaMapMarker, FaPhoneAlt } from 'react-icons/fa'

const Footer = ({ settings }) => {

  const currentYear = new Date().getFullYear();

  return (
    <div>
      <footer className="text-center text-lg-start bg-black text-muted">
        <div className="">
          <div className="container text-center text-md-start pt-5">
            <div class="get-in-touch text-center bg-black">
              <p className='fs_24 text-white fw_600'>Get in Touch With Us
                <NavLink className='position-relative serv-cta-tel' to={`tel:${settings && settings[0] && settings[0].PhoneNumber ? settings[0].PhoneNumber : ''}`} ><FaPhoneAlt />{" "} {settings && settings[0] && settings[0].PhoneNumber ? settings[0].PhoneNumber : ''}</NavLink>
                <NavLink className='position-relative serv-cta-tel' to={`mailto:${settings && settings[0] && settings[0].EmailAddress ? settings[0].EmailAddress : ''}`} ><FaEnvelope />{" "} {settings && settings[0] && settings[0].EmailAddress ? settings[0].EmailAddress : ''}</NavLink>
              </p>
              <p class="fs_24 fw_600 text-white pt-2 pb-2"> <FaMapMarker className='text__blue' /> {settings && settings[0] && settings[0].OfficeAddress ? settings[0].OfficeAddress : ''}</p>
            </div>
          </div>
        </div>

        <div className="text-center border-top text-white bg-black p-4 fs_16 fw_600" style={{ backgroundColor: "rgba(0, 0, 0, 0.05);" }}>
          © {currentYear} Copyright:{" "}
          <NavLink className="text-decoration-underline fs_16 fw_600" to="/">BakeMyBook</NavLink>
        </div>
        <div className="text-center border-top text-white bg__blue p-4 fs_16 fw_600" style={{ backgroundColor: "rgba(0, 0, 0, 0.05);" }}>
          <p className='fs_13 text-center text-white '>Disclaimer:<br />The services provided by BakeMyBook become clients' property once delivered. BakeMyBook will not hold any ownership<br /> rights once your work is successfully accepted by a publishing house.</p>
        </div>
      </footer>
    </div>
  )
}

export default Footer