import React, { useRef, useEffect, useState } from 'react'
import { Form, Button } from 'react-bootstrap';
import emailjs from '@emailjs/browser'
import { toast } from 'react-toastify';
import { useNavigate, useLocation } from 'react-router-dom';
import { FaArrowRight } from 'react-icons/fa';

const AllPageMailForm = ({ PageNameTracking, setShow }) => {

    const navigate = useNavigate();

    const dataSend = useRef({
        user_name: '',
        user_email: '',
        user_phone_number: '',
        PageNameTracking: PageNameTracking,
    });

    const { user_name, user_email, user_phone_number } = dataSend;

    const sendEmail = async (e) => {
        e.preventDefault();


        if (PageNameTracking === '[Popup Form]') {
            setShow(false);
        }

        if (user_name !== '' && user_email !== '' && user_phone_number !== '') {
            emailjs.sendForm('service_tyrmgff', 'template_sz4s2rc', dataSend.current, 'Y-z7nap2-6B3-7ixo')
                .then((result) => {
                    navigate('/thank-you');
                    toast.success('Email Sent Successfully!');
                }, (error) => {
                    toast.error('Temporary Closed');
                });
        } else {
            toast.error('All fields are mandatory');
        }
    };

    return (
        <div>
            <Form onSubmit={sendEmail} ref={dataSend}>
                <div className="row align-items-center">
                    <div className="col-12 col-md mb-lg-0 mb-3">
                        <Form.Group controlId="formBasicPassword">
                            <Form.Control
                                type="text"
                                name="user_name"
                                placeholder="Full Name"
                                className="shadow-none"
                                required
                            />
                            <Form.Control
                                type="text"
                                name="PageNameTracking"
                                className="shadow-none"
                                hidden
                                required
                                value={PageNameTracking}
                            />
                        </Form.Group>
                    </div>
                    <div className="col-12 col-md mb-lg-0 mb-3">
                        <Form.Group controlId="formBasicEmail">
                            <Form.Control
                                type="email"
                                name="user_email"
                                placeholder="Enter Your email"
                                className="shadow-none"
                                required
                            />
                        </Form.Group>
                    </div>
                    <div className="col-12 col-md mb-lg-0 mb-3">
                        <Form.Group controlId="formBasicPassword">
                            <Form.Control
                                type="number"
                                name="user_phone_number"
                                placeholder="phone number"
                                className="shadow-none"
                                required
                            />
                        </Form.Group>
                    </div>
                    <div className="col-12 col-md mb-lg-0 mb-3">
                        <div className="text-center">
                            <Button type="submit" className="rounded get_quote">
                                Get a free quote <FaArrowRight />
                            </Button>
                        </div>
                    </div>
                </div>
            </Form>
        </div>
    )
}

const AllPageContactForm = ({ setShow }) => {

    const navigate = useNavigate();

    const [PageNameTracking, setPageNameTracking] = useState('');

    const location = useLocation();

    useEffect(() => {
        let name = '';

        switch (location.pathname) {
            case '/':
                name = '[Home Page Popup Form]';
                break;
            case '/thank-you':
                name = '[ThankYou Page Popup Form]';
                break;
            case '/contact-us':
                name = '[Contact Us Page Popup Form]';
                break;
            case '/portfolio':
                name = '[Portfolio Page Popup Form]';
                break;
            case '/about-us':
                name = '[About Us Page Popup Form]';
                break;
            case '/testimonial':
                name = '[Testimonial Page Popup Form]';
                break;
            case '/book-publishing':
                name = '[Book Publishing Page Popup Form]';
                break;
            case '/book-printing-services':
                name = '[Book Printing Page Popup Form]';
                break;
            case '/book-cover-design':
                name = '[Book Cover Design Page Popup Form]';
                break;
            case '/ebook-ghost-writing':
                name = '[Ebook GhostWriting Page Popup Form]';
                break;
            case '/book-editing':
                name = '[Book Editing Page Popup Form]';
                break;
            case '/ebook-writing':
                name = '[Ebook Writing Page Popup Form]';
                break;
            case '/audio-book':
                name = '[AudioBook Page Popup Form]';
                break;
            case '/book-proofreading':
                name = '[BookProofreading Page Popup Form]';
                break;
            case '/self-publishing':
                name = '[SelfPublishing Page Popup Form]';
                break;

            default:
                break;
        }

        setPageNameTracking(name);
    }, [location.pathname]);

    const dataSend = useRef({
        user_name: '',
        user_email: '',
        user_phone_number: '',
        user_message: '',
        PageNameTracking: PageNameTracking,
    });

    const { user_name, user_email, user_phone_number, user_message } = dataSend;

    const sendEmail = async (e) => {
        e.preventDefault();

        if (user_name !== '' && user_email !== '' && user_phone_number !== '' && user_message !== '') {
            emailjs.sendForm('service_tyrmgff', 'template_sz4s2rc', dataSend.current, 'Y-z7nap2-6B3-7ixo')
                .then((result) => {
                    navigate('/thank-you');
                    setShow(false);
                    toast.success('Email Sent Successfully!');
                }, (error) => {
                    toast.error('Temporary Closed');
                });
        } else {
            toast.error('All fields are mandatory');
        }
    };

    return (
        <div>
            <Form onSubmit={sendEmail} ref={dataSend}>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Control
                        type="text"
                        name="user_name"
                        placeholder="Full Name"
                        className="shadow-none"
                        required
                    />
                    <Form.Control
                        type="text"
                        name="PageNameTracking"
                        className="shadow-none"
                        hidden
                        required
                        value={PageNameTracking}
                    />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Control
                        type="email"
                        name="user_email"
                        placeholder="Enter Your email"
                        className="shadow-none"
                        required
                    />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Control
                        type="number"
                        name="user_phone_number"
                        placeholder="phone number"
                        className="shadow-none"
                        required
                    />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Control
                        as="textarea"
                        name="user_message"
                        rows={5}
                        className="shadow-none"
                        placeholder="type Message"
                        required
                    />
                </Form.Group>
                <div className="text-center">
                    <Button type="submit" className="rounded get_quote">
                        Submit
                    </Button>
                </div>
            </Form>
        </div>
    )
}

const ContactPageForm = ({ PageNameTracking }) => {

    const navigate = useNavigate();

    const dataSend = useRef({
        user_name: '',
        user_email: '',
        user_phone_number: '',
        user_message: '',
        PageNameTracking: PageNameTracking,
    });

    const { user_name, user_email, user_phone_number, user_message } = dataSend;

    const sendEmail = async (e) => {
        e.preventDefault();

        if (user_name !== '' && user_email !== '' && user_phone_number !== '' && user_message !== '') {
            emailjs.sendForm('service_tyrmgff', 'template_sz4s2rc', dataSend.current, 'Y-z7nap2-6B3-7ixo')
                .then((result) => {
                    navigate('/thank-you');
                    toast.success('Email Sent Successfully!');
                }, (error) => {
                    toast.error('Temporary Closed');
                });
        } else {
            toast.error('All fields are mandatory');
        }

    };

    return (
        <div>
            <Form onSubmit={sendEmail} ref={dataSend}>
                <div className="row">
                    <div className="col-md-6">
                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Control
                                type="text"
                                name="user_name"
                                placeholder="Full Name"
                                className="shadow-none"
                                required
                            />
                            <Form.Control
                                type="text"
                                hidden
                                name="PageNameTracking"
                                className="shadow-none"
                                required
                                value={PageNameTracking}
                            />
                        </Form.Group>
                    </div>
                    <div className="col-md-6">
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Control
                                type="email"
                                name="user_email"
                                placeholder="Enter Your email"
                                className="shadow-none"
                                required
                            />
                        </Form.Group>
                    </div>
                </div>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Control
                        type="number"
                        name="user_phone_number"
                        placeholder="phone number"
                        className="shadow-none"
                        required
                    />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Control
                        as="textarea"
                        name="user_message"
                        rows={5}
                        className="shadow-none"
                        placeholder="type Message"
                        required
                    />
                </Form.Group>
                <div className="text-center">
                    <Button type="submit" className="rounded get_quote">
                        Send a message
                    </Button>
                </div>
            </Form>
        </div>
    )
}

export { AllPageMailForm, ContactPageForm, AllPageContactForm };
