import React from 'react'
import MetaTitleDesc from '../MetaTitleDesc';
import { FaCheckCircle } from 'react-icons/fa';
import { NavLink } from 'react-router-dom';
import BookPublishingLogos from '../assets/images/book-publishing-logos.webp'
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js'
import { Autoplay } from 'swiper';
import 'swiper/swiper-bundle.min.css'
import {AllPageContactForm} from '../components/MailForm';

const PartnerImages = [
  require("../assets/images/1.webp"),
  require("../assets/images/2.webp"),
  require("../assets/images/3.webp"),
  require("../assets/images/2.webp"),
  require("../assets/images/1.webp"),
  require("../assets/images/3.webp"),
  require("../assets/images/1.webp"),
  require("../assets/images/2.webp"),
  require("../assets/images/3.webp"),
  require("../assets/images/2.webp"),
  require("../assets/images/1.webp"),
  require("../assets/images/3.webp"),
];

const Images = [

  require("../assets/images/slider/book_2.webp"),
  require("../assets/images/slider/book_3.webp"),
  require("../assets/images/slider/book_4.webp"),
  require("../assets/images/slider/book_5.webp"),
  require("../assets/images/slider/book_6.webp"),
  require("../assets/images/slider/book_7.webp"), 
  require("../assets/images/slider/book_8.webp"),
  require("../assets/images/slider/book_10.webp"),
  require("../assets/images/slider/A-boat-reincarnated-1.webp"),
  require("../assets/images/slider/Frank-Roselli-New-eBook-Cover-new.webp"),
  require("../assets/images/slider/Abraham-Loorig-New.webp"),
  require("../assets/images/slider/as-if-i-knew-what-i-was-talking-about-1.webp"),
  require("../assets/images/slider/Big-mistake-New.webp"),
  require("../assets/images/slider/Celebrating-KMET.webp"),
  require("../assets/images/slider/Desires-behind-a-waterfall.webp"),
  require("../assets/images/slider/Filled-with-his-love-1.webp"),
  require("../assets/images/slider/I-am-kind-of-my-castle.webp"),
  require("../assets/images/slider/mommy-what-is-a-slave-1.webp"),
  require("../assets/images/slider/My-Trip-With-Elvis.webp"),
  require("../assets/images/slider/Patty-Glaser-Final-Wraparound-Cover-New.webp"),
  require("../assets/images/slider/Rugged-Ribbons.webp"),
  require("../assets/images/slider/she-is-the-poem-2.webp"),
  require("../assets/images/slider/the-defination-guide-to-1.webp"),
  require("../assets/images/slider/the-devil-picked-the-girls-1.webp"),
  require("../assets/images/slider/the-jesters-2-1.webp"),
  require("../assets/images/slider/the-sea-that-bind-us.webp"),
  require("../assets/images/slider/The-umbrella-of-worship.webp"),
  require("../assets/images/slider/The-wheels-on-the-bus.webp"),
  require("../assets/images/slider/what-lives-2.webp"),
  require("../assets/images/slider/Wild-Birds-in-my-backyard.webp"),
  require("../assets/images/slider/Loving-Hard-when-theyre-hard-to-love.webp"),

];

const Portfolio = ({ settings }) => {

  return (
    <>
      <MetaTitleDesc />
      <div className="portfolio-banner d-flex justify-content-center align-items-center">
        <div className="container">
          <div className="text-center">
            <p className="fs_18 fw_600 text-white">Our Work</p>
            <h1 className="fs_43 fw_400 text-white lh_1 mb-4">Portfolio</h1>
          </div>
        </div>
      </div>
      <div className="partner-section bg__blue py-3">
        <div className="container">
          <Swiper
            slidesPerView={1}
            spaceBetween={20}
            grabCursor={true}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            modules={[Autoplay]}
            breakpoints={{
              '991': {
                slidesPerView: 5,
              },
              '768': {
                slidesPerView: 4,
              },
              '0': {
                slidesPerView: 2,
              },
            }}
            className="mySwiper"
          >
            {PartnerImages.map((item, index) => (
              <SwiperSlide key={index}>
                <div className='position-relative overflow-hidden'>
                  <img src={item} alt="" className="img-fluid" />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
      <div className="collection-section py-5">
        <div className="container">
          <div className="row">
            {Images.map((item, i) => (
              <div className="col-md-2half col-6 mb-3" key={i}>
                <img src={item} alt="" className="img-fluid" />
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="packages-section py-5 bg__black">
        <div className="container">
          <div className="row mb-3 align-items-center">
            <div className="col mb-lg-0 mb-4 content text-lg-start text-center">
              <h2 className="fs_44 fw_400 text-white lh_1 mb-4">Why wait? Call us today to get your
                book published in the best manner.</h2>
              <p className="fs_20 mb-3 fw_400 text-white">BakeMyBook helps book enthusiasts achieve their dreams. We bring colors to
                your thoughts and expressions. From book publishing to book marketing, we
                offer all kinds of services:</p>
              <ul className="list-unstyled checklist row">
                <li className="col-md-4 col-12 text-start pb-1 text-white"><FaCheckCircle className='text-success' /> Ghostwriting</li>
                <li className="col-md-4 col-12 text-start pb-1 text-white"><FaCheckCircle className='text-success' /> Novel writing</li>
                <li className="col-md-4 col-12 text-start pb-1 text-white"><FaCheckCircle className='text-success' /> Scriptwriting</li>
                <li className="col-md-4 col-12 text-start pb-1 text-white"><FaCheckCircle className='text-success' /> Book layout, typesetting &amp; formatting</li>
                <li className="col-md-4 col-12 text-start pb-1 text-white"><FaCheckCircle className='text-success' /> Book publishing</li>
                <li className="col-md-4 col-12 text-start pb-1 text-white"><FaCheckCircle className='text-success' /> Cover design </li>
                <li className="col-md-4 col-12 text-start pb-1 text-white"><FaCheckCircle className='text-success' /> Book editing</li>
                <li className="col-md-4 col-12 text-start pb-1 text-white"><FaCheckCircle className='text-success' /> Book illustration</li>
                <li className="col-md-4 col-12 text-start pb-1 text-white"><FaCheckCircle className='text-success' /> Children books</li>
                <li className="col-md-4 col-12 text-start pb-1 text-white"><FaCheckCircle className='text-success' /> Recipe books</li>
                <li className="col-md-4 col-12 text-start pb-1 text-white"><FaCheckCircle className='text-success' /> Biography</li>
                <li className="col-md-4 col-12 text-start pb-1 text-white"><FaCheckCircle className='text-success' /> Book printing</li>
                <li className="col-md-4 col-12 text-start pb-1 text-white"><FaCheckCircle className='text-success' /> Book marketing</li>
                <li className="col-md-4 col-12 text-start pb-1 text-white"><FaCheckCircle className='text-success' /> Audiobooks</li>
              </ul>
              <img src={BookPublishingLogos} alt="" className="img-fluid" />
            </div>
            <div className="col-md-4">
              <div className="banner__form card border-0">
                <div className="card-body">
                  <h4 className="mb-2 fs_36 fw_700 text__shadow text__blue text-center">Sign up to AVAIL
                  </h4>
                  <span className="d-block bg__blue mb-2 text-center fs_16 text-white rounded py-2">50% Discount</span>
                  <p className='fs_18 fw_700 text__shadow text__blue text-center mb-4'>Discuss Your Project With Our Experts</p>
                  <AllPageContactForm PageNameTracking="[Portfolio Page Contact Form]" />
                </div>
              </div>
            </div>
          </div>
          <p className="fs_20 mb-3 fw_400 text-white text-center">Enjoy a one-stop shopping experience at BakeMyBook as we solve all your
            book writing and publishing needs. Give the professional look to your manuscript
            that it deserves. Choose from our wide range of self-published book marketing
            service and get started today!</p>
        </div>
      </div>
    </>
  )
}

export default Portfolio