import React, {useRef} from 'react'
import MetaTitleDesc from '../MetaTitleDesc';
import { FaPhoneAlt, FaCheckCircle } from 'react-icons/fa';
import { NavLink } from 'react-router-dom';
import BookPublishingLogos from '../assets/images/book-publishing-logos.webp'
import { Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js'
import 'swiper/swiper-bundle.min.css'
import ctaSideImgOne from '../assets/images/cta-side-img-1.webp'
import { AllPageContactForm } from '../components/MailForm';

const PartnerImages = [
  require("../assets/images/1.webp"),
  require("../assets/images/2.webp"),
  require("../assets/images/3.webp"),
  require("../assets/images/2.webp"),
  require("../assets/images/1.webp"),
  require("../assets/images/3.webp"),
  require("../assets/images/1.webp"),
  require("../assets/images/2.webp"),
  require("../assets/images/3.webp"),
  require("../assets/images/2.webp"),
  require("../assets/images/1.webp"),
  require("../assets/images/3.webp"),
];

const AboutUs = ({ settings, handleShow, openLiveChat }) => {

  return (
    <>
      <MetaTitleDesc />
      <div className="contact-banner d-flex justify-content-center align-items-center">
        <div className="container">
          <div className="text-center">
            <h1 className="fs_43 fw_400 text-white pt-5 lh_1 mb-4">Have you any Query?</h1>
            <p className="fs_18 fw_600 text-white">Feel free to Contact Us</p>
          </div>
        </div>
      </div>
      <div className="partner-section bg__blue py-3">
        <div className="container">
          <Swiper
            slidesPerView={1}
            spaceBetween={20}
            grabCursor={true}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            modules={[Autoplay]}
            breakpoints={{
              '991': {
                slidesPerView: 5,
              },
              '768': {
                slidesPerView: 4,
              },
              '0': {
                slidesPerView: 2,
              },
            }}
            className="mySwiper"
          >
            {PartnerImages.map((item, index) => (
              <SwiperSlide key={index}>
                <div className='position-relative overflow-hidden'>
                  <img src={item} alt="" className="img-fluid" />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
      <div className="cta-section py-5">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6 mb-lg-0 mb-4">
              <h2 className='fs_47 fw_400 text-white mb-3'>We are passionate about making
                your dreams come true.</h2>
              <p className='text-white fs_16 fw_400'>We have book fanatics on board who give the most beautiful
                depiction of your ideas. Whether it is any particular stage of book
                writing and publishing or the complete process, we take care of
                everything to utmost perfection.</p>
              <p className='text-white fs_16 fw_400'>Our highly qualified and committed book enthusiasts love to dive into
                the ocean of words to bring out the best for your publications. We are a
                team of professionals who work together wholeheartedly to delight the
                clients with the final version of their conception.</p>
              <p className='text-white fs_16 fw_400'>Annually we publish thousands of books that become best-sellers.
                Our consistent quality work speaks for itself.</p>
              <div className="theme-btns serv-cta-btn inr-tab-btn cta-tab-btn mt-4 d-flex justify-content-lg-start justify-content-center gap-2">
                <NavLink className='position-relative serv-cta-tel' to={`tel:${settings && settings[0] && settings[0].PhoneNumber ? settings[0].PhoneNumber : ''}`} ><FaPhoneAlt />{" "} {settings && settings[0] && settings[0].PhoneNumber ? settings[0].PhoneNumber : ''}</NavLink>
                <NavLink to="#" className="position-relative serv-cta-tel" onClick={handleShow}>Get Started</NavLink>
                <NavLink to="#" onClick={openLiveChat} className="serv-cta-chat chats position-relative text-white   ">
                  Live chat
                </NavLink>
              </div>
            </div>
            <div className="col-md-6">
              <img src={ctaSideImgOne} alt="" className="img-fluid" />
            </div>
          </div>
        </div>
      </div>
      <div className="process-section pt-5" style={{ background: '#1f1f1f' }}>
        <div className="container">
          <div className="row justify-content-between align-items-center position-relative">
            <div className="col-md-12">
              <h2 className="fs_60 fw_400 text-white text-center">How can we help you?</h2>
            </div>
            <div className="col-md-12">
              <div class="row justify-content-center">
                <div class="col-12 col-sm-4 mb-lg-0 mb-3">
                  <div class="serv-bx">
                    <h2 className='fs_55 text__blue'>01</h2>
                    <h2 className='fs_26 fw_400 text-white'>Meticulous Research and Draft Creation</h2>
                    <p className='fs_16 fw_400 text-white'>We engage in healthy dialogue with the client to fully understand the concept. Our research specialists get down to even the tiniest relevant detail to help draft the most pertinent plot.</p>
                  </div>
                </div>
                <div class="col-12 col-sm-4 mb-lg-0 mb-3">
                  <div class="serv-bx">
                    <h2 className='fs_55 text__blue'>02</h2>
                    <h2 className='fs_26 fw_400 text-white'>Original Content Writing at its Best</h2>
                    <p className='fs_16 fw_400 text-white'>Our avid writers create original, engaging, and captivating content that keeps the readers flipping the pages. No matter what genre of book you need, we have expert writers that cater to all kinds.</p>
                  </div>
                </div>
                <div class="col-12 col-sm-4 mb-lg-0 mb-3">
                  <div class="serv-bx">
                    <h2 className='fs_55 text__blue'>03</h2>
                    <h2 className='fs_26 fw_400 text-white'>Thorough Review and Editing services</h2>
                    <p className='fs_16 fw_400 text-white'>Our meticulous review process ensures not even the minutest mistake or shortcoming is left out. We polish your content to give the most professional touch to your script.</p>
                  </div>
                </div>
                <div class="col-12 col-sm-4 mb-lg-0 mb-3">
                  <div class="serv-bx">
                    <h2 className='fs_55 text__blue'>04</h2>
                    <h2 className='fs_26 fw_400 text-white'>Professional Formatting and Designing</h2>
                    <p className='fs_16 fw_400 text-white'>Once we complete the editing, we accomplish professional formatting and designing as per the client&#39;s preferences. We use the latest design and technology to create the most unique and appealing books.</p>
                  </div>
                </div>
                <div class="col-12 col-sm-4 mb-lg-0 mb-3">
                  <div class="serv-bx">
                    <h2 className='fs_55 text__blue'>05</h2>
                    <h2 className='fs_26 fw_400 text-white'>Marketing and Publishing Services</h2>
                    <p className='fs_16 fw_400 text-white'>Our job does not finish here. We work extensively on the promotional strategy for our clients, thereby magnetically drawing readers to the books. We brand our books as providing the most pleasurable reading experience.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="packages-section border__top_blue border__bottom_blue py-5 bg__black">
        <div className="container">
          <div className="row align-items-center">
            <div className="col mb-lg-0 mb-4 content text-lg-start text-center">
              <h4 className="fs_35 fw_900 text-white lh_1 mb-4">Book genres that we cater to</h4>
              <ul className="list-unstyled checklist row">
                <li className="col-md-4 col-12 text-start pb-1 text-white"><FaCheckCircle /> Biography</li>
                <li className="col-md-4 col-12 text-start pb-1 text-white"><FaCheckCircle /> Autobiography</li>
                <li className="col-md-4 col-12 text-start pb-1 text-white"><FaCheckCircle /> Drama/Suspense</li>
                <li className="col-md-4 col-12 text-start pb-1 text-white"><FaCheckCircle /> Non-fiction</li>
                <li className="col-md-4 col-12 text-start pb-1 text-white"><FaCheckCircle /> Romantic</li>
                <li className="col-md-4 col-12 text-start pb-1 text-white"><FaCheckCircle /> Comedy </li>
                <li className="col-md-4 col-12 text-start pb-1 text-white"><FaCheckCircle /> Recipe book</li>
                <li className="col-md-4 col-12 text-start pb-1 text-white"><FaCheckCircle /> Children storybook</li>
                <li className="col-md-4 col-12 text-start pb-1 text-white"><FaCheckCircle /> Science Fiction</li>
                <li className="col-md-4 col-12 text-start pb-1 text-white"><FaCheckCircle /> Folklore</li>
                <li className="col-md-4 col-12 text-start pb-1 text-white"><FaCheckCircle /> Poetry</li>
                <li className="col-md-4 col-12 text-start pb-1 text-white"><FaCheckCircle /> Fable</li>
                <li className="col-md-4 col-12 text-start pb-1 text-white"><FaCheckCircle /> Fairy tale</li>
                <li className="col-md-4 col-12 text-start pb-1 text-white"><FaCheckCircle /> Essays</li>
                <li className="col-md-4 col-12 text-start pb-1 text-white"><FaCheckCircle /> Mythology</li>
                <li className="col-md-4 col-12 text-start pb-1 text-white"><FaCheckCircle /> Legend</li>
              </ul>
              <img src={BookPublishingLogos} alt="" className="img-fluid" />
              <p className="fs_16 fw_400 mt-4 text-white">Call us today for a pleasantly memorable book creation journey.</p>
            </div>
            <div className="col-md-4">
              <div className="banner__form card border-0">
                <div className="card-body">
                  <h4 className="mb-2 fs_36 fw_700 text__shadow text__blue text-center">Sign up to AVAIL
                  </h4>
                  <span className="d-block bg__blue mb-2 text-center fs_16 text-white rounded py-2">50% Discount</span>
                  <p className='fs_18 fw_700 text__shadow text__blue text-center mb-4'>Discuss Your Project With Our Experts</p>
                  <AllPageContactForm PageNameTracking="[About Us Page Contact Form]" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="cta-section why-choose py-5">
        <div className="container">
          <div className="text-center mb-3">
            <h2 className="text-white fs_47 fw_400">Drop Us A Line</h2>
            <p className="text-white fs_16 fw_400 mt-3">Fill out your details below and we’ll get back to you shortly.</p>
            <h2 className="text-white fs_40 fw_400">Discuss Your Project</h2>
          </div>
          <div className="theme-btns serv-cta-btn inr-tab-btn cta-tab-btn mt-2 d-flex justify-content-center gap-2">
            <NavLink className='position-relative serv-cta-tel' to={`tel:${settings && settings[0] && settings[0].PhoneNumber ? settings[0].PhoneNumber : ''}`} ><FaPhoneAlt />{" "} {settings && settings[0] && settings[0].PhoneNumber ? settings[0].PhoneNumber : ''}</NavLink>
            <NavLink to="#" className="position-relative serv-cta-tel" onClick={handleShow}>Get Started</NavLink>
            <NavLink to="#" onClick={openLiveChat} className="serv-cta-chat chats position-relative text-white">
              Live chat
            </NavLink>
          </div>
        </div>
      </div>
    </>
  )
}

export default AboutUs