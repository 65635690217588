import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { db } from './Firebase';
import { collection, getDocs } from "firebase/firestore";
import { useLocation } from 'react-router-dom';

const MetaTitleDesc = () => {

    const [settings, setSettings] = useState([]);

    const [metatitle, setMetaTitle] = useState('');
    const [metadesc, setMetaDesc] = useState('');

    const location = useLocation();

    useEffect(() => {
        let newMetaTitle = '';
        let newMetaDesc = '';

        switch (location.pathname) {
            case '/':
                newMetaTitle = 'Get Professional Book Marketing Services - Hire A Book Marketing Expert';
                newMetaDesc = 'Bake My Book offers the best book marketing services. We specialize in marketing a children&#039;s book, christan book and book promotion.';
                break;
            case '/contact-us':
                newMetaTitle = 'Contact - bakemybook';
                newMetaDesc = 'Contact - bakemybook';
                break;
            case '/portfolio':
                newMetaTitle = 'Portfolio - bakemybook';
                newMetaDesc = 'Portfolio - bakemybook';
                break;
            case '/about-us':
                newMetaTitle = 'About Us - bakemybook';
                newMetaDesc = 'About Us - bakemybook';
                break;
            case '/testimonial':
                newMetaTitle = 'Testimonial - bakemybook';
                newMetaDesc = 'Testimonial - bakemybook';
                break;
            case '/book-publishing':
                newMetaTitle = 'Get the Best Quality Book Publishing Services';
                newMetaDesc = 'Tired of being just a writer? Become an author! With our immaculate and complete book publishing services, you are assured of a widespread publishing experience.';
                break;
            case '/book-printing-services':
                newMetaTitle = 'Choose the Most Optimal Book Printing Services for Your Publication';
                newMetaDesc = 'We offer the most comprehensive and premium book printing services for your book, following all the necessary protocols and specifications.';
                break;
            case '/book-cover-design':
                newMetaTitle = 'Get the Book Cover Design Services that Justify the Hard Work Beneath';
                newMetaDesc = 'Our professional cover designers represent the core themes and ideas in your book in the book cover designs we produce.';
                break;
            case '/ebook-ghost-writing':
                newMetaTitle = 'Choose the Best eBook Ghostwriting Services for Your Masterpiece';
                newMetaDesc = 'Make the book of your dreams rise above rough drafts and incomplete thoughts. With the help of one of our professional eBook ghostwriters for hire.';
                break;
            case '/book-editing':
                newMetaTitle = 'Expert Book Editing Services to Add that Oomph!';
                newMetaDesc = 'Our professional editing services ensure your book is succulent, un-put-downable and gets the best reviews.';
                break;
            case '/ebook-writing':
                newMetaTitle = 'Premium eBook Writing Services - Promote Your eBook Today!';
                newMetaDesc = 'Our affordable eBook writing service takes pride in producing authentic, informative, and appealing eBooks.';
                break;
            case '/audio-book':
                newMetaTitle = 'Choose the Best Service for Audiobooks Today - BakeMyBook';
                newMetaDesc = 'Professional audiobook services - BakeMyBook dedicated professionals team up with the client and help them achieve their dream of publishing an audiobook.';
                break;
            case '/book-proofreading':
                newMetaTitle = 'Professional Book Proofreading Services';
                newMetaDesc = 'Our proofreading service experts eliminate all the errors and inconsistencies in your manuscript. We perform scrupulous editing and proofreading to deliver a ready-to-publish book.';
                break;
            case '/self-publishing':
                newMetaTitle = 'Premium Self Book Publishing Services for All Your Genres';
                newMetaDesc = 'BakeMyBook understands how important the manuscript is and ensures seamless Self publishing solutions for you.';
                break;
                case '/404':
                newMetaTitle = 'Page Not Found';
                newMetaDesc = 'Page Not Found';
                break;

            default:
                break;
        }

        setMetaTitle(newMetaTitle);
        setMetaDesc(newMetaDesc);
    }, [location.pathname]);

    let currentUrl = '';
    if (typeof window !== 'undefined') {
        currentUrl = window.location.href;
    }

    const fetchSettings = async () => {
        const BlogColl = collection(db, "settingData");
        await getDocs(BlogColl).then((querySnapshot) => {
            const newData = querySnapshot.docs
                .map((doc) => ({ ...doc.data(), id: doc.id }));
            setSettings(newData);
        });
    };

    useEffect(() => {
        fetchSettings();
    }, [])

    return (
        <>
            <Helmet>
                <title>{metatitle}</title>
                <meta name="description" content={metadesc} />
                {currentUrl && <link rel="canonical" href={currentUrl} />}
                <meta name="robots" content={settings && settings[0] && settings[0].MetaRobots ? settings[0].MetaRobots : ''} />
            </Helmet>
        </>
    );
};

export default MetaTitleDesc;
