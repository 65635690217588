import React, { useState, useEffect } from 'react'
import Header from './Header'
import Footer from './Footer'
import Routers from '../Routers/Routers'
import { NavLink } from 'react-router-dom'
import { FaComment, FaPhone, FaTimes } from 'react-icons/fa'
import { Modal } from 'react-bootstrap'
import { db } from '../Firebase';
import { collection, getDocs } from "firebase/firestore";
import { AllPageContactForm } from '../components/MailForm'

const Layouts = () => {

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setShow(true);
        }, 10000);
        return () => {
            clearTimeout(timeoutId);
        };
    }, []);

    const [settings, setSettings] = useState([]);

    const fetchSettings = async () => {
        const BlogColl = collection(db, "settingData");
        await getDocs(BlogColl).then((querySnapshot) => {
            const newData = querySnapshot.docs
                .map((doc) => ({ ...doc.data(), id: doc.id }));
            setSettings(newData);
        });
    };

    useEffect(() => {
        fetchSettings();
    }, [])


    const [timeRemaining, setTimeRemaining] = useState(180); // Initial time in seconds (3 minutes)
    const [isResetting, setIsResetting] = useState(false);

    useEffect(() => {
        const interval = setInterval(() => {
            if (timeRemaining > 0) {
                setTimeRemaining((prevTime) => prevTime - 1);
            } else {
                setIsResetting(true);
                setTimeRemaining(180); 
            }
        }, 1000);

        return () => clearInterval(interval);
    }, [timeRemaining]);

    const formatTime = (time) => (time < 10 ? `0${time}` : `${time}`);

    const minutes = formatTime(Math.floor(timeRemaining / 60));
    const seconds = formatTime(timeRemaining % 60);

    const openLiveChat = () => {
        window.LC_API.open_chat_window();
    }

    return (
        <>
            <Header settings={settings} openLiveChat={openLiveChat} />
            <div>
                <div id="sideContactBtns">
                    <button onClick={handleShow} className="rotate90" >Get a Quote</button>
                    <NavLink to={`tel:${settings && settings[0] && settings[0].PhoneNumber ? settings[0].PhoneNumber : ''}`}>
                        <FaPhone />
                    </NavLink>
                    <NavLink to="#">
                        <FaComment onClick={openLiveChat}/>
                    </NavLink>
                </div>
            </div>
            <div>
                <Routers settings={settings} openLiveChat={openLiveChat} handleClose={handleClose} handleShow={handleShow} />
            </div>
            <Footer settings={settings} />
            <div className="bless_friday" onClick={openLiveChat}>
                <div className="bless_friday_top">
                    <p>SALE</p>
                </div>
                <h2>Get <span>50% Off</span></h2>
                <div className="countdown">
                    <span>00</span>
                    <span>:</span>
                    <span id="mins">{minutes}</span>
                    <span>:</span>
                    <span id="secs">{seconds}</span>
                </div>
            </div>
            <div>
                <Modal centered show={show}>
                    <Modal.Body className='popup__form'>
                        <button type="button" class="fancybox-close-small close" onClick={handleClose}>
                            X
                        </button>
                        <div className="row">
                            <div className="col-md-6"></div>
                            <div className="col-md-6">
                                <div className='card bg-transparent border-0'>
                                    <div className="card-body p-0">
                                        <h4 className="mb-2 fs_36 fw_700 text__shadow text-white text-center">Avail Amazing
                                        </h4>
                                        <span className="d-block bg__blue mb-2 text-center fs_16 text-white rounded py-2">50% Discount Now!</span>
                                        <AllPageContactForm setShow={setShow} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        </>
    )
}

export default Layouts