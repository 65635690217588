import React, {useRef} from 'react'
import MetaTitleDesc from '../MetaTitleDesc';
import { FaPhoneAlt, FaChevronRight } from 'react-icons/fa';
import { NavLink } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js'
import { Autoplay } from 'swiper';
import 'swiper/swiper-bundle.min.css'
import Client1 from '../assets/images/client-1.webp'
import Client5 from '../assets/images/client-5.webp'
import Client7 from '../assets/images/client-7.webp'
import Client8 from '../assets/images/client-8.webp'
import Client11 from '../assets/images/client-11.webp'
import Client12 from '../assets/images/client-12.webp'
import Client13 from '../assets/images/client-13.webp'
import Client14 from '../assets/images/client-14.webp'
import Client15 from '../assets/images/client-15.webp'
import TestimonialImg from '../assets/images/testimonial-img-01.webp'
import Slider from 'react-slick';

const PartnerImages = [
  require("../assets/images/1.webp"),
  require("../assets/images/2.webp"),
  require("../assets/images/3.webp"),
  require("../assets/images/2.webp"),
  require("../assets/images/1.webp"),
  require("../assets/images/3.webp"),
  require("../assets/images/1.webp"),
  require("../assets/images/2.webp"),
  require("../assets/images/3.webp"),
  require("../assets/images/2.webp"),
  require("../assets/images/1.webp"),
  require("../assets/images/3.webp"),
];

const Testimonial = ({ settings, handleShow, openLiveChat }) => {

  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  const sliderRef = useRef(null);

  const next = () => {
    sliderRef.current.slickNext();
  };

  return (
    <>
      <MetaTitleDesc />
      <div className="testimonial-banner d-flex justify-content-center align-items-center">
        <div className="container">
          <div className="text-center">
            <p className="fs_18 fw_600 pt-5 text-white">What Our Clients Say</p>
            <h1 className="fs_43 fw_400 text-white lh_1 mb-4">Testimonial</h1>
          </div>
        </div>
      </div>
      <div className="partner-section bg__blue py-3">
        <div className="container">
          <Swiper
            slidesPerView={1}
            spaceBetween={20}
            grabCursor={true}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            modules={[Autoplay]}
            breakpoints={{
              '991': {
                slidesPerView: 5,
              },
              '768': {
                slidesPerView: 4,
              },
              '0': {
                slidesPerView: 2,
              },
            }}
            className="mySwiper"
          >
            {PartnerImages.map((item, index) => (
              <SwiperSlide key={index}>
                <div className='position-relative overflow-hidden'>
                  <img src={item} alt="" className="img-fluid" />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
      <div className="cta-section py-5">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6 mb-lg-0 mb-4">
              <h2 className='fs_47 fw_400 text-white mb-3'>Author Maria Lamari Is Full Of Praises For BakeMyBook</h2>
              <p className='text-white fs_16 fw_400'>Maria Lamari, writing the fantastic book, A Peacock's Gotta Fly, for John Giacomazzi loved her Book Publishing and Marketing Experience with us.</p>
              <p className='text-white fs_16 fw_400'>The professional positive-thinker John Giacomazzi traveled the world,
                snowboarded, coached and inspired young up-and-coming athletes… but near his fortieth birthday, he was diagnosed with ALS.</p>
              <p className='text-white fs_16 fw_400'>The great adventurer has penned his emotional and uplifting journey of how he didn’t let his diagnosis and disease take his smile, and that’s the message he sends out in this book.</p>
              <p className='text-white fs_16 fw_400'>If you want wisdom that makes you laugh and cry and face overwhelming loss
                with grace, and leave feeling inspired, check out John Giacomazzi’s book (transcribed by Maria Lamari) on  Barnes &amp; Noble, Walmart and beyond.</p>
              <div className="theme-btns serv-cta-btn inr-tab-btn cta-tab-btn mt-4 d-flex justify-content-lg-start justify-content-center gap-2">
                <NavLink className='position-relative serv-cta-tel' to={`tel:${settings && settings[0] && settings[0].PhoneNumber ? settings[0].PhoneNumber : ''}`} ><FaPhoneAlt />{" "} {settings && settings[0] && settings[0].PhoneNumber ? settings[0].PhoneNumber : ''}</NavLink>
                <NavLink to="#" className="position-relative serv-cta-tel" onClick={handleShow}>Get Started</NavLink>
                <NavLink to="#" onClick={openLiveChat} className="serv-cta-chat chats position-relative text-white">
                  Live chat
                </NavLink>
              </div>
            </div>
            <div className="col-md-6">
              <iframe width="514" height="360" src="https://www.youtube.com/embed/pM8NT7JErbY" title="Listen to What Our Latest Client Maria Lamari Has to Say #bakemybook #bmb #bookstagram" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
          </div>
        </div>
      </div>
      <div className="bg__blue py-5">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6 mb-lg-0 mb-4">
              <h2 className='fs_30 fw_400 text-white mb-3'>Listen to what Whitney Fleming has to say about her Self-publishing journey on Amazon with BakeMyBook. Her book, Loving Hard When They’re Hard to Love, was an Amazon Bestseller in Spring, 2023.</h2>
              <div className="theme-btns serv-cta-btn inr-tab-btn cta-tab-btn mt-4 d-flex justify-content-lg-start justify-content-center gap-2">
                <NavLink className='position-relative serv-cta-tel' to={`tel:${settings && settings[0] && settings[0].PhoneNumber ? settings[0].PhoneNumber : ''}`} ><FaPhoneAlt />{" "} {settings && settings[0] && settings[0].PhoneNumber ? settings[0].PhoneNumber : ''}</NavLink>
                <NavLink to="#" className="position-relative serv-cta-tel" onClick={handleShow}>Get Started</NavLink>
                <NavLink to="#" onClick={openLiveChat} className="serv-cta-chat chats position-relative text-white   ">
                  Live chat
                </NavLink>
              </div>
            </div>
            <div className="col-md-6">
              <iframe width="560" height="315" src="https://www.youtube.com/embed/0YqZX0RAHtw?si=AO_LotMpoStWJeuX" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
          </div>
        </div>
      </div>
      <div className="our-testimonial py-5 bg-detail spacing__x">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-lg-6 col-sm-12 text-center col-12 col-xs-12 col-md-12">
              <div className="img-box text-center d-flex justify-content-center align-items-center">
                <img alt="BakeMyBook" src={TestimonialImg} className="img-fluid" />
              </div>
            </div>
            <div className="col-lg-4 col-sm-12 col-12 col-xs-12 col-md-12">
              <div className="heading">
                <h6 className="best-heading-here">Testimonials</h6>
                <h3 className='fs_35 fw_700 text-white'>We are pleased with our consumer feedback.<span>.</span></h3>
                <p className='fs_16 fw_400 text-white'><i>We are a well-known name in the book writing industry, and we are pleased when our clients give us feedback.</i></p>
              </div>
              <Slider ref={sliderRef} {...sliderSettings} className="single-review-slider">
              <div className="single-review-box">
                  <img width="76" height="76" className='mb-2' alt="Phantom Writing" src={Client12} />
                  <p className='fs_16 fw_400 text-white'><i>"Great experience with Bake My Book.
                    <br />
                    Theo was my point of contact, and he did a great job managing me throughout the publishing process. I published my book at an extremely busy time in my life, so I needed some hand-holding. BMB did the entire process, from editing to helping me market and everything in between. Theo painstakingly answered all my questions, and in the few instances we went off track, he responded promptly and with good information. He also went the extra mile for me by helping me with Amazon. My book sales are doing well, and I appreciate all the support I received from Bake My Book."</i></p>
                  <h6 className='fs_14 text-captalize text-white'>Whitney Fleming</h6>
                </div>
                <div className="single-review-box">
                  <img width="76" height="76" className='mb-2' alt="Phantom Writing" src={Client11} />
                  <p className='fs_16 fw_400 text-white'><i>"Very professional service. Yes, you can self-publish these days, but navigating the process is complicated and time consuming. With BakeMyBook handling the logistics and offering valuable publishing insight, I can devote my time to writing. It's well worth the small investment. BakeMyBook's fees are more than fair and as the author I still retain 100% rights to my work, meaning I can still sell my book to a major publishing house in the future. When I complete my next work, I'll again call Theo and his team."</i></p>
                  <h6 className='fs_14 text-captalize text-white'>David Lutes</h6>
                </div>
                <div className="single-review-box">
                  <img width="76" height="76" className='mb-2' alt="Phantom Writing" src={Client8} />
                  <p className='fs_16 fw_400 text-white'><i>"I have been shopping for the right team to edit my books all have failed until I found bake my book. They are extremely personal and precise. They are genuinely interested and enthusiastic about your work and the editing is second to none. When they are finished your book will be flawless. I didn't think it was possible I will continue working with bake my book in the future."</i></p>
                  <h6 className='fs_14 text-captalize text-white'>Frank Roselli</h6>
                </div>
                <div className="single-review-box">
                  <img width="76" height="76" className='mb-2' alt="Phantom Writing" src={Client1} />
                  <p className='fs_16 fw_400 text-white'><i>"I just released my second book through BakeMyBook and I couldn't be happier. The experience has been genuinely positive. Theo answered my questions with patience, humor, and professionalism in a timely manner. The editing process was painless. The team made appropriate changes and suggestions without changing my voice. If you are considering self-publishing through BakeMyBook, I highly recommend them. They are able to help you every step of the way. I will submit my third book soon as well as any future projects.
                    Thank you so much for making me feel like one in a million and not just one out of many."</i></p>
                  <h6 className='fs_14 text-captalize text-white'>Patty Glaser</h6>
                </div>
                <div className="single-review-box">
                  <img width="76" height="76" className='mb-2' alt="Phantom Writing" src={Client14} />
                  <p className='fs_16 fw_400 text-white'><i>"I am glad I called bakemybook.com this summer.
                    Mr. Theo Madden was very professional, picking up the phone always and he answered all my questions. Also he gave me ideas how the whole process for publishing my book on Amazon would be. He helped me with everything I had difficulties and he understood what my book is all about. More over the whole process was done extremely professional and I will work with this company every time when I have a new book to publish.
                    I was talking to some other self publishing companies but they did not even come close to Bakemybook.com
                    They were only looking for how to make money and that I felt easily. Not explaining all details, not answering all my questions, being inpatient with me."</i></p>
                  <h6 className='fs_14 text-captalize text-white'>George Shumanov</h6>
                </div>
                <div className="single-review-box">
                  <img width="76" height="76" className='mb-2' alt="Phantom Writing" src={Client15} />
                  <p className='fs_16 fw_400 text-white'><i>"This company has done a great job for me…again! My second book using this company was published in December 2022. The team working with me showed a sincere interest in giving me an excellent book. I highly recommend Bake My Book."</i></p>
                  <h6 className='fs_14 text-captalize text-white'>Jenny Zimmer</h6>
                </div>
                <div className="single-review-box">
                  <img width="76" height="76" className='mb-2' alt="Phantom Writing" src={Client7} />
                  <p className='fs_16 fw_400 text-white'><i>"As a first-time author, I sought a publisher and editor who would direct me thought the process of actualizing my work into print. I sought sensitivity to my novella from an editor who could see beyond its initial flaws and refine its message, not view it merely as a vanity project.<br />
                    After a very disappointing and expensive false start with another company, I discovered Bake My Book. I received expert individual guidance, patient advice and encouragement from Theo Madden, the senior book publishing consultant at BMB. My many questions were answered promptly (and delivered with good humor). He steered the process of developing my book over the hurdles of publishing, making my novella a beautiful reality. His interest and enthusiasm for my project led to enlisting Francesca McCollough, the senior book editor. Her involvement taught me to write with precision and clarity, showing me ways to improve dialogue, plot pacing, and characterization. Her patience and incisive critique helped me become a confident writer. I became the fortunate recipient of a one-on-one tutorial in creative writing!
                    It is true that a book is judged by its cover. I submitted my ideas to the art team who translated my book into a beautiful cover that visually continues the story. It is exactly what I wanted. Working with the team at BMB has been a gratifying experience. Already, I have another project I will be publishing with this company.
                    Many thanks!"</i></p>
                  <h6 className='fs_14 text-captalize text-white'>Carol Funk</h6>
                </div>
                <div className="single-review-box">
                  <img width="76" height="76" className='mb-2' alt="Phantom Writing" src={Client13} />
                  <p className='fs_16 fw_400 text-white'><i>"I had a wonderful experience with BakeMyBook, fantastic experience actually, from the moment I called in, spoke with Theo, he was very calm and knowledgeable about what I needed, that wiped away all those anxiety and worries I was having due to my previous encounters with other publishers. My experience has been wonderful, Theo and his team held my hand throughout, from book editing, formatting, book cover design to service and delivery. I was kept informed of the progress and process, their feedback, customer service impeccable. I will certainly recommend BakeMyBook and I intend using their services for my upcoming publishing needs. Thank you so much for going the extra mile with the publication of my novel , Things unsaid "memoirs of an empty nester".
                    BakeMyBook -They are simply the BEST."</i></p>
                  <h6 className='fs_14 text-captalize text-white'>Dorothy Okung</h6>
                </div>
                <div className="single-review-box">
                  <img width="76" height="76" className='mb-2' alt="Phantom Writing" src={Client5} />
                  <p className='fs_16 fw_400 text-white'><i>“Theo Madden was very helpful with the creativity of my book, it was a great experience overall.”</i></p>
                  <h6 className='fs_14 text-captalize text-white'>Tracy Hudson</h6>
                </div>
              </Slider>
              <div className="text-end">
                <button className="btn bg-white border-0 rounded-pill" onClick={next}>
                  Next <FaChevronRight className='text-success' />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Testimonial