import React from 'react'
import { FaPhoneAlt } from 'react-icons/fa';
import { NavLink } from 'react-router-dom'

const IndustryTab = ({ Title, handleShow, Desc, openLiveChat, tabimg, settings }) => {
    return (
        <div className='pt-5'>
            <div className="row align-items-center mt-5">
                <div className="col-12 mb-lg-0 mb-4 col-sm-7 col-md-6">
                    <div className="text-start">
                        <h2 className='fs_60 pb-4 fw_400 text__black'>{Title}</h2>
                        <p className='fs_16 fw_400 pb-4'>{Desc}</p>
                    </div>
                    <div className="theme-btns serv-cta-btn inr-tab-btn mt-4 d-flex justify-content-lg-start justify-content-center gap-2">
                        <NavLink className='position-relative serv-cta-tel' to={`tel:${settings && settings[0] && settings[0].PhoneNumber ? settings[0].PhoneNumber : ''}`} ><FaPhoneAlt />{" "} {settings && settings[0] && settings[0].PhoneNumber ? settings[0].PhoneNumber : ''}</NavLink>
                        <NavLink to="#" className="position-relative serv-cta-tel" onClick={handleShow}>Get Started</NavLink>
                        <NavLink to="#" onClick={openLiveChat} className="serv-cta-chat chats position-relative text-white   ">
                            Live chat
                        </NavLink>
                    </div>
                </div>
                <div className="col-12 col-sm-5 col-md-6">
                    <div className="indus-tab-img bg__blue mt-lg-0 mt-5 rounded-5 pb-3">
                        <img className="img-fluid" alt="Phantom Writing" style={{marginTop:'-60px'}} src={tabimg} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default IndustryTab