import React from 'react'
import MetaTitleDesc from '../MetaTitleDesc';
import { FaEnvelope, FaPhoneAlt, FaLocationArrow, FaCheckCircle } from 'react-icons/fa';
import { NavLink } from 'react-router-dom';
import BookPublishingLogos from '../assets/images/book-publishing-logos.webp'
import { Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js'
import 'swiper/swiper-bundle.min.css'
import {AllPageContactForm,ContactPageForm} from '../components/MailForm';

const PartnerImages = [
  require("../assets/images/1.webp"),
  require("../assets/images/2.webp"),
  require("../assets/images/3.webp"),
  require("../assets/images/2.webp"),
  require("../assets/images/1.webp"),
  require("../assets/images/3.webp"),
  require("../assets/images/1.webp"),
  require("../assets/images/2.webp"),
  require("../assets/images/3.webp"),
  require("../assets/images/2.webp"),
  require("../assets/images/1.webp"),
  require("../assets/images/3.webp"),
];

const ContactUs = ({ settings }) => {

  return (
    <>
      <MetaTitleDesc />
      <div className="contact-banner d-flex justify-content-center align-items-center">
        <div className="container">
          <div className="text-center">
          <p className="fs_18 fw_600 text-white">GET A QUOTE</p>
            <h1 className="fs_43 fw_400 text-white lh_1 mb-4">Contact Us</h1>
          </div>
        </div>
      </div>
      <div className="partner-section bg__blue py-3">
        <div className="container">
          <Swiper
            slidesPerView={1}
            spaceBetween={20}
            grabCursor={true}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            modules={[Autoplay]}
            breakpoints={{
              '991': {
                slidesPerView: 5,
              },
              '768': {
                slidesPerView: 4,
              },
              '0': {
                slidesPerView: 2,
              },
            }}
            className="mySwiper"
          >
            {PartnerImages.map((item, index) => (
              <SwiperSlide key={index}>
                <div className='position-relative overflow-hidden'>
                  <img src={item} alt="" className="img-fluid" />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
      <div className="contact-form py-5">
        <div className="container">
          <div className="text-center mb-4">
            <h2 className='fs_25 fw_700 text__black'>Contact Us Now</h2>
          </div>
          <div className="row">
            <div className='col-lg-6 col-md-6 col-sm-6 col-12'>
              <div className="card bg__blue">
                <div className="card-body">
                  <h4 className='fs_20 mb-3 text-white'>We Help You Write And Publish A Book</h4>
                  <p className='text-white'>Please fill out this form to reach us</p>
                  <ul className='list-unstyled mt-4'>
                    <li className='text-white mb-2'>
                      <FaPhoneAlt />{" "}
                      <NavLink to={`tel:${settings && settings[0] && settings[0].PhoneNumber ? settings[0].PhoneNumber : ''}`}>{settings && settings[0] && settings[0].PhoneNumber ? settings[0].PhoneNumber : ''} </NavLink>
                    </li>
                    <li className='text-white mb-2'>
                      <FaEnvelope />{" "}
                      <NavLink to={`mailto:${settings && settings[0] && settings[0].EmailAddress ? settings[0].EmailAddress : ''}`}>{settings && settings[0] && settings[0].EmailAddress ? settings[0].EmailAddress : ''} </NavLink>
                    </li>
                    <li className='text-white'>
                      <FaLocationArrow />{" "}
                      <span> {settings && settings[0] && settings[0].OfficeAddress ? settings[0].OfficeAddress : ''} </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className='col-lg-6 col-md-6 col-sm-6 col-12'>
             <ContactPageForm PageNameTracking={'[Contact Page Main Form]'}/>
            </div>
          </div>
        </div>
      </div>
      <div className="packages-section border__top_blue border__bottom_blue py-5 bg__black">
        <div className="container">
          <div className="row align-items-center">
            <div className="col mb-lg-0 mb-4 content text-lg-start text-center">
            <h2 className="fs_44 fw_400 text-white lh_1 mb-4">Why wait? Call us today to get your
                book published in the best manner.</h2>
              <p className="fs_20 mb-3 fw_400 text-white">BakeMyBook helps book enthusiasts achieve their dreams. We bring colors to
                your thoughts and expressions. From book publishing to book marketing, we
                offer all kinds of services:</p>
              <ul className="list-unstyled checklist row">
                <li className="col-md-4 col-12 text-start pb-1 text-white"><FaCheckCircle className='text-success' /> Ghostwriting</li>
                <li className="col-md-4 col-12 text-start pb-1 text-white"><FaCheckCircle className='text-success' /> Novel writing</li>
                <li className="col-md-4 col-12 text-start pb-1 text-white"><FaCheckCircle className='text-success' /> Scriptwriting</li>
                <li className="col-md-4 col-12 text-start pb-1 text-white"><FaCheckCircle className='text-success' /> Book layout, typesetting &amp; formatting</li>
                <li className="col-md-4 col-12 text-start pb-1 text-white"><FaCheckCircle className='text-success' /> Book publishing</li>
                <li className="col-md-4 col-12 text-start pb-1 text-white"><FaCheckCircle className='text-success' /> Cover design </li>
                <li className="col-md-4 col-12 text-start pb-1 text-white"><FaCheckCircle className='text-success' /> Book editing</li>
                <li className="col-md-4 col-12 text-start pb-1 text-white"><FaCheckCircle className='text-success' /> Book illustration</li>
                <li className="col-md-4 col-12 text-start pb-1 text-white"><FaCheckCircle className='text-success' /> Children books</li>
                <li className="col-md-4 col-12 text-start pb-1 text-white"><FaCheckCircle className='text-success' /> Recipe books</li>
                <li className="col-md-4 col-12 text-start pb-1 text-white"><FaCheckCircle className='text-success' /> Biography</li>
                <li className="col-md-4 col-12 text-start pb-1 text-white"><FaCheckCircle className='text-success' /> Book printing</li>
                <li className="col-md-4 col-12 text-start pb-1 text-white"><FaCheckCircle className='text-success' /> Book marketing</li>
                <li className="col-md-4 col-12 text-start pb-1 text-white"><FaCheckCircle className='text-success' /> Audiobooks</li>
              </ul>
              <img src={BookPublishingLogos} alt="" className="img-fluid" />
            </div>
            <div className="col-md-4">
              <div className="banner__form card border-0">
              <div className="card-body">
                  <h4 className="mb-2 fs_36 fw_700 text__shadow text__blue text-center">Sign up to AVAIL
                  </h4>
                  <span className="d-block bg__blue mb-2 text-center fs_16 text-white rounded py-2">50% Discount</span>
                  <p className='fs_18 fw_700 text__shadow text__blue text-center mb-4'>Discuss Your Project With Our Experts</p>
                  <AllPageContactForm PageNameTracking="[Contact Page Contact Form]" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ContactUs