import React from 'react'
import { Routes, Route,Navigate } from 'react-router-dom'
import Home from '../Pages/Home'
import BookPublishing from '../Pages/BookPublishing'
import BookPrintingServices from '../Pages/BookPrintingServices'
import BookCoverDesign from '../Pages/BookCoverDesign'
import EBookGhostWriting from '../Pages/EBookGhostWriting'
import BookEditing from '../Pages/BookEditing'
import EBookWriting from '../Pages/EBookWriting'
import AudioBook from '../Pages/AudioBook'
import BookProofreading from '../Pages/BookProofreading'
import SelfPublishing from '../Pages/SelfPublishing'
import ContactUs from '../Pages/ContactUs'
import AboutUs from '../Pages/AboutUs'
import Portfolio from '../Pages/Portfolio'
import Testimonial from '../Pages/Testimonials'
import ThankYou from '../Pages/ThankYou'
import Error from '../Pages/Error'
import AMZPublishingCompany from '../Pages/AMZPublishingCompany'

const Routers = ({ settings, openLiveChat, handleShow }) => {

  return (
    <>
      <Routes basename="/">
        <Route path="/" element={<Home openLiveChat={openLiveChat} handleShow={handleShow} settings={settings} />} />
        <Route path="/book-publishing" element={<BookPublishing openLiveChat={openLiveChat} handleShow={handleShow} settings={settings} />} />
        <Route path="/book-printing-services" element={<BookPrintingServices openLiveChat={openLiveChat} handleShow={handleShow} settings={settings} />} />
        <Route path="/book-cover-design" element={<BookCoverDesign openLiveChat={openLiveChat} handleShow={handleShow} settings={settings} />} />
        <Route path="/ebook-ghost-writing" element={<EBookGhostWriting openLiveChat={openLiveChat} handleShow={handleShow} settings={settings} />} />
        <Route path="/book-editing" element={<BookEditing openLiveChat={openLiveChat} handleShow={handleShow} settings={settings} />} />
        <Route path="/ebook-writing" element={<EBookWriting openLiveChat={openLiveChat} handleShow={handleShow} settings={settings} />} />
        <Route path="/audio-book" element={<AudioBook openLiveChat={openLiveChat} handleShow={handleShow} settings={settings} />} />
        <Route path="/book-proofreading" element={<BookProofreading openLiveChat={openLiveChat} handleShow={handleShow} settings={settings} />} />
        <Route path="/self-publishing" element={<SelfPublishing openLiveChat={openLiveChat} handleShow={handleShow} settings={settings} />} />
        <Route path="/contact-us" element={<ContactUs openLiveChat={openLiveChat} handleShow={handleShow} settings={settings} />} />
        <Route path="/about-us" element={<AboutUs openLiveChat={openLiveChat} handleShow={handleShow} settings={settings} />} />
        <Route path="/portfolio" element={<Portfolio openLiveChat={openLiveChat} handleShow={handleShow} settings={settings} />} />
        <Route path="/testimonial" element={<Testimonial openLiveChat={openLiveChat} handleShow={handleShow} settings={settings} />} />
        <Route path="/thank-you" element={<ThankYou openLiveChat={openLiveChat} handleShow={handleShow} settings={settings} />} />
        <Route path="/amz-publishing-company" element={<AMZPublishingCompany openLiveChat={openLiveChat} handleShow={handleShow} settings={settings} />} />
        <Route path="/404" element={<Error openLiveChat={openLiveChat} handleShow={handleShow} settings={settings} />} />
        <Route path="*" element={<Navigate to='/404' openLiveChat={openLiveChat} handleShow={handleShow} settings={settings} />} />
      </Routes>
    </>
  )
}

export default Routers