import React from 'react'
import MetaTitleDesc from '../MetaTitleDesc';
import { FaHome } from 'react-icons/fa';
import { NavLink } from 'react-router-dom';

const PartnerImages = [
  require("../assets/images/1.webp"),
  require("../assets/images/2.webp"),
  require("../assets/images/3.webp"),
  require("../assets/images/2.webp"),
  require("../assets/images/1.webp"),
  require("../assets/images/3.webp"),
  require("../assets/images/1.webp"),
  require("../assets/images/2.webp"),
  require("../assets/images/3.webp"),
  require("../assets/images/2.webp"),
  require("../assets/images/1.webp"),
  require("../assets/images/3.webp"),
];

const Error = ({ settings }) => {
  return (
    <>
      <MetaTitleDesc />
      <div className="testimonial-banner d-flex justify-content-center align-items-center">
        <div className="container">
          <div className="text-center">
            <FaHome className='text-success fs_50' />
            <h1 className="fs_45 fw_700 text-white lh_1 pt-3 mb-4">404</h1>
            <p className='fs_18 text-white'>Page Not Found</p>
            <NavLink className="btn rounded-pill bg-white fs_16 fw_600" to="/">Back to Home</NavLink>
          </div>
        </div>
      </div>
    </>
  )
}

export default Error