import React, { useState, useRef } from 'react'
import { FaCheckCircle, FaPhoneAlt, FaChevronRight } from 'react-icons/fa';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'react-bootstrap';
import { NavLink as RouterNavLink } from 'react-router-dom';
import MetaTitleDesc from '../MetaTitleDesc';
import BookPublishingLogos from '../assets/images/book-publishing-logos.webp'
import WhyChooseSideImg from '../assets/images/why-choose-side-img.webp'
import IndustryTab from '../components/IndustryTab';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js'
import { Autoplay } from 'swiper';
import ServiceSideBox from '../assets/images/service-side-img.webp'
import BannerImg from '../assets/images/banner-img-book.webp'
import ctaSideImgOne from '../assets/images/cta-side-img-1.webp'
import ctaSideImgTwo from '../assets/images/cta-side-img-2.webp'
import ctaSideImgThree from '../assets/images/cta-side-img-3.webp'
import Client1 from '../assets/images/client-1.webp'
import Client5 from '../assets/images/client-5.webp'
import Client7 from '../assets/images/client-7.webp'
import Client8 from '../assets/images/client-8.webp'
import Client11 from '../assets/images/client-11.webp'
import Client12 from '../assets/images/client-12.webp'
import Client13 from '../assets/images/client-13.webp'
import Client14 from '../assets/images/client-14.webp'
import Client15 from '../assets/images/client-15.webp'
import TestimonialImg from '../assets/images/testimonial-img-01.webp'
import { AllPageMailForm, AllPageContactForm } from '../components/MailForm';
import Slider from 'react-slick';
import tab1 from '../assets/images/Educational.webp'
import tab2 from '../assets/images/Health.webp'
import tab3 from '../assets/images/Romance.webp'
import tab4 from '../assets/images/Comic.webp'
import tab5 from '../assets/images/Drama.webp'
import tab6 from '../assets/images/Horror.webp'
import tab7 from '../assets/images/Sci-fi.webp'
import tab8 from '../assets/images/Travel.webp'
import Portfolio from '../components/Portfolio';

const PartnerImages = [
  require("../assets/images/1.webp"),
  require("../assets/images/2.webp"),
  require("../assets/images/3.webp"),
  require("../assets/images/2.webp"),
  require("../assets/images/1.webp"),
  require("../assets/images/3.webp"),
  require("../assets/images/1.webp"),
  require("../assets/images/2.webp"),
  require("../assets/images/3.webp"),
  require("../assets/images/2.webp"),
  require("../assets/images/1.webp"),
  require("../assets/images/3.webp"),
];

const ServiceBox = [
  {
    title: 'Ghost Book Writing',
    description: `You might have fantastic ideas that urge you to pick up your pen. However, lack
    of time, proper writing training, or any other obstacle might hinder you. Our
    ghostwriters understand what you want to convey through the words.`,
    image: require("../assets/images/service-icon-1.webp"),
  },
  {
    title: 'Book Editing',
    description: `Amateur authors who know how to craft magic with words can benefit from our
    exceptional book editing services. BakeMyBook offers the most professional
    developmental and copy editing to get the best version of your writing.`,
    image: require("../assets/images/service-icon-6.webp"),
  },
  {
    title: 'Book Publishing',
    description: `Many well-written books go unnoticed because the author does not get
    professional guidance. We connect our clients with the best distribution network
    Barnes &amp; Noble or Ingramspark, to get the highest readership.`,
    image: require("../assets/images/service-icon-3.webp"),
  },
  {
    title: 'Book Marketing',
    description: `We understand every client&#39;s specific market needs, assess the target audience,
    and tailor your book marketing plan. Using SEO, social media management, video
    trailers, we offer solutions emphasizing our client&#39;s credibility.`,
    image: require("../assets/images/service-icon-4.webp"),
  },
  {
    title: 'Book Design',
    description: `Whether it is book cover design or illustrations, we got you covered. Our experts
    ranging from arts and design fields execute your brilliant book cover designs and
    illustrated artworks with unlimited revisions until you are nothing short of
    pleased.`,
    image: require("../assets/images/service-icon-2.webp"),
  },
  {
    title: 'Book Formatting',
    description: `Interior Formatting, Typesetting, and Layout of a book is the heart of your book’s inner
    appearance. Our formatters ensure using fonts, margins, heading styles etc. that perfectly align
    with the theme of your book.`,
    image: require("../assets/images/service-icon-5.webp"),
  },
]

const EBookWriting = ({ settings, handleShow, openLiveChat }) => {

  const tabs = [
    {
      id: 3,
      title: "Romance",

      content: <IndustryTab openLiveChat={openLiveChat} tabimg={tab3} settings={settings} handleShow={handleShow} Title={'Romance'} Desc={`Hire one of our skilled writers to make your reader's life simple and provide them with an accessible dictionary to read. Our qualified writers complete your
      dictionary in an approachable style and have a strong understanding of the
      language.`} />,
    },
    {
      id: 8,
      title: "Science Fiction",
      content: <IndustryTab openLiveChat={openLiveChat} tabimg={tab7} settings={settings} handleShow={handleShow} Title={'Science Fiction'} Desc={`Science-fiction book publishing may bring your thoughts to reality. Authors should offer their futuristic realms, galactic experiences, and technical
      marvels. Editing, formatting, and designing their books might improve their
      appearance. Help them promote and distribute their publications.`} />,
    },
    {
      id: 5,
      title: "Drama",

      content: <IndustryTab openLiveChat={openLiveChat} tabimg={tab5} settings={settings} handleShow={handleShow} Title={'Drama'} Desc={`Health book publishing services can be helpful for authors who want to share their knowledge and expertise on wellness and health topics. Our service for
      publishing health books has a number of features to help authors, such as
      editing, formatting, and design services to make sure the final product is
      polished and professional.`} />,
    },
    {
      id: 4,
      title: "Christianity",

      content: <IndustryTab openLiveChat={openLiveChat} tabimg={tab4} settings={settings} handleShow={handleShow} Title={'Christianity'} Desc={`Our drama book publishing service is just one way that we, as publishers, try to help budding authors get their work out into the world. Our drama publishing
      service provides a venue for dramatists to share their work with an audience,
      and it includes tools to help authors, such as editing, formatting, and design,
      create a polished and professional book.`} />,
    },
    {
      id: 1,
      title: "Educational",
      content: <IndustryTab openLiveChat={openLiveChat} tabimg={tab1} settings={settings} handleShow={handleShow} Title={'Educational'} Desc={`
      Action and adventure books are a popular type of book in the publishing world.
      These books are called "action and adventure" novels. These stories will keep
      you entertained with action, excitement, and unexpected twists.,
      
    `} />,
    },
    {
      id: 9,
      title: "Travel",
      content: <IndustryTab openLiveChat={openLiveChat} tabimg={tab8} settings={settings} handleShow={handleShow} Title={'Travel'} Desc={`Let us provide your readers with the greatest travel tale in a virtual tour. Travel book publishing allows authors to share their travels, culture, and
      wanderlust. edit, format, and design their books for a professional look, and
      assist in promoting and distributing them.`} />,
    },
    {
      id: 2,
      title: "Health",
      content: <IndustryTab openLiveChat={openLiveChat} tabimg={tab2} settings={settings} handleShow={handleShow} Title={'Health'} Desc={`In the world of book publishing, comics are thrilling. The comics genre is wide and developing, offering something for every reader. Our book printing services
      and digital versions are a popular medium for readers and collectors.`} />,
    },
    {
      id: 6,
      title: "Horror",
      content: <IndustryTab openLiveChat={openLiveChat} tabimg={tab6} settings={settings} handleShow={handleShow} Title={'Horror'} Desc={`Readers of many types enjoy this genre, which pairs well. Give your viewers a top-notch horror story. To ensure a polished and professional result, our horror
      book publishing offer editing, formatting, and design services to authors. To
      help writers establish their horror reputations, including marketing and
      distribution support.`} />,
    },
  ];

  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  const sliderRef = useRef(null);

  const next = () => {
    sliderRef.current.slickNext();
  };

  const [activeTab, setActiveTab] = useState(tabs[0].id);

 

  return (
    <>
      <MetaTitleDesc />
      <div className="home-banner d-flex justify-content-center align-items-center">
        <div className="container">
          <div className="row justify-content-between align-items-center">
            <div className="col mb-lg-0 mb-4 content text-center">
              <h1 className="fs_55 fw_400 text-white lh_1 mb-4 pt-5">Our Professional eBook Writers Can Make Your Story a Best-Seller!</h1>
              <p className="fs_16 fw_600 text-white">We Construct Your Outstanding Book Using Our Premium eBook Writing Services.</p>
              <ul className='text-center my-1'>
                <li className='fs_16 fw_400 d-inline-block text-white mb-1 px-3'> <FaCheckCircle className='text-success fw-bold' /> Get Your eBook Written Today</li>
                <li className='fs_16 fw_400 d-inline-block text-white mb-1 px-3'> <FaCheckCircle className='text-success fw-bold' /> Fastest Turn Around Time</li>
                <li className='fs_16 fw_400 d-inline-block text-white mb-1 px-3'> <FaCheckCircle className='text-success fw-bold' /> 100% Money Back Guarantee</li>
                <li className='fs_16 fw_400 d-inline-block text-white mb-1 px-3'> <FaCheckCircle className='text-success fw-bold' /> 100% Satisfaction Guaranteed</li>
                <li className='fs_16 fw_400 d-inline-block text-white mb-1 px-3'> <FaCheckCircle className='text-success fw-bold' /> Get <b class="text_off">50%</b> Off Today</li>
                <li className='fs_16 fw_400 d-inline-block text-white mb-1 px-3'> <FaCheckCircle className='text-success fw-bold' /> 100% Copyright Ownership</li>
              </ul>
            </div>
            <div className="col-md-12">
              <div className="card border-0">
                <div className="p-0 card-body">
                  <AllPageMailForm PageNameTracking="[EBook Writing Page Banner Form]" />
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="banner-img">
                <img src={BannerImg} alt="" className="img-fluid" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="partner-section bg__blue py-3">
        <div className="container">
          <Swiper
            slidesPerView={1}
            spaceBetween={20}
            grabCursor={true}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            modules={[Autoplay]}
            breakpoints={{
              '991': {
                slidesPerView: 5,
              },
              '768': {
                slidesPerView: 4,
              },
              '0': {
                slidesPerView: 2,
              },
            }}
            className="mySwiper"
          >
            {PartnerImages.map((item, index) => (
              <SwiperSlide key={index}>
                <div className='position-relative overflow-hidden'>
                  <img src={item} alt="" className="img-fluid" />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
      <div className="cta-section py-5">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6 mb-lg-0 mb-4">
              <h2 className='fs_47 fw_400 text-white mb-3'>Let Our Elite eBook Writers Produce Your Supreme Book</h2>
              <p className='text-white fs_16 fw_400'>At BakeMyBook, we give wings to your imagination and plot. No matter what
                ideas you bring, no matter the concept, our professional writers will transform your story into an epic book. Our eBook writers for hire are used to delivering past our potential, and beyond your expectations.</p>
              <p className='text-white fs_16 fw_400'>Our affordable eBook writing service takes pride in producing authentic,
                informative, and appealing eBooks. Left wondering, “Are there any decent book writers near me?” Look no further. We ensure top-quality eBook writing service in USA for every unique author-client. Our eBook writing company is fond of winning the hearts of our client’s target audience.</p>
              <div className="theme-btns serv-cta-btn inr-tab-btn cta-tab-btn mt-4 d-flex justify-content-lg-start justify-content-center gap-2">
                <RouterNavLink className='position-relative serv-cta-tel' to={`tel:${settings && settings[0] && settings[0].PhoneNumber ? settings[0].PhoneNumber : ''}`} ><FaPhoneAlt />{" "} {settings && settings[0] && settings[0].PhoneNumber ? settings[0].PhoneNumber : ''}</RouterNavLink>
                <RouterNavLink to="#" className="position-relative serv-cta-tel" onClick={handleShow}>Get Started</RouterNavLink>
                <RouterNavLink to="#" onClick={openLiveChat} className="serv-cta-chat chats position-relative text-white   ">
                  Live chat
                </RouterNavLink>
              </div>
            </div>
            <div className="col-md-6">
              <img src={ctaSideImgOne} alt="" className="img-fluid" />
            </div>
          </div>
        </div>
      </div>
      <div className="services-section py-5">
        <div className="container">
          <div className="row">
            <div className="col-md-5">
              <h2 className="text__black fs_42 fw_400">Choose the Best Book Self-Publishing Service</h2>
              <p className="text__black fw_400 fs_16">BakeMyBook offers a diverse range of book publishing services. Call us today and enjoy the most affordable book publishing and marketing services for
                your self-published book.</p>
            </div>
            <div className="col-md-7">
              <div className="card bg__blue">
                <div className="card-body">
                  <div className="row align-items-center">
                    <div className="col-md-4 d-lg-block d-none" style={{ marginTop: "-60px" }}>
                      <img src={ServiceSideBox} alt="" className="img-fluid" />
                    </div>
                    <div className="col-md-4 d-lg-none d-block">
                      <img src={ServiceSideBox} alt="" className="img-fluid" />
                    </div>
                    <div className="col-md-8 mb-lg-0 mb-4">
                      <h3 className="text-white fs_22">Add Flavor to Your Editing!</h3>
                      <p className="fs_14 fw_400 text-white">Create the most compellingly edited books with the help of BakeMyBook’s editing professionals.</p>
                      <div className="theme-btns mt-4 d-flex gap-2">
                        <RouterNavLink className='position-relative serv-cta-tel' to={`tel:${settings && settings[0] && settings[0].PhoneNumber ? settings[0].PhoneNumber : ''}`} ><FaPhoneAlt />{" "} {settings && settings[0] && settings[0].PhoneNumber ? settings[0].PhoneNumber : ''}</RouterNavLink>
                        <RouterNavLink to="#" onClick={openLiveChat} className="serv-cta-chat chats position-relative">
                          Live chat
                        </RouterNavLink>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row pt-5">
            {ServiceBox.map((item, i) => (
              <div key={i} className="col-12 col-md-6 col-lg-4 mb-4">
                <RouterNavLink to="#">
                  <div className="serv-bx">
                    <img className="img-fluid mb-3" alt="Phantom Writing" src={item.image} />
                    <h3 className='mb-3 fs_28 fw_500 text__black'>{item.title}</h3>
                    <p className='mb-3 fs_14 fw_400 text__black'>{item.description}</p>
                  </div>
                </RouterNavLink>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="cta-section py-5">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6">
              <h2 className="text-white fs_60 mb-3 fw_400">Sign up with BakeMyBook &amp; enjoy fantastic discount offers for the most remarkable ebook writing services USA.</h2>
              <div className="theme-btns serv-cta-btn inr-tab-btn cta-tab-btn mt-5 flex-wrap justify-content-lg-start justify-content-center d-flex gap-2">
                <RouterNavLink className='position-relative serv-cta-tel' to={`tel:${settings && settings[0] && settings[0].PhoneNumber ? settings[0].PhoneNumber : ''}`} ><FaPhoneAlt />{" "} {settings && settings[0] && settings[0].PhoneNumber ? settings[0].PhoneNumber : ''}</RouterNavLink>
                <RouterNavLink to="#" className="position-relative serv-cta-tel" onClick={handleShow}>Get Started</RouterNavLink>
                <RouterNavLink to="#" onClick={openLiveChat} className="serv-cta-chat chats position-relative text-white   ">
                  Live chat
                </RouterNavLink>
              </div>
            </div>
            <div className="col-md-6">
              <img src={ctaSideImgTwo} alt="" className="img-fluid" />
            </div>
          </div>
        </div>
      </div>
      <Portfolio/>
      <div className="cta-section py-5">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6">
              <h2 className="text-white fs_60 mb-3 fw_400">Join hands with BakeMyBook for the most wonderful eBook writing journey</h2>
              <p className="fs_16 fw_400 text-white">We are used to wowing our authors with how we transform their thoughts and ideas with our most brilliant eBook writing acumen.</p>
              <div className="theme-btns serv-cta-btn inr-tab-btn cta-tab-btn mt-5 flex-wrap justify-content-lg-start justify-content-center d-flex gap-2">
                <RouterNavLink className='position-relative serv-cta-tel' to={`tel:${settings && settings[0] && settings[0].PhoneNumber ? settings[0].PhoneNumber : ''}`} ><FaPhoneAlt />{" "} {settings && settings[0] && settings[0].PhoneNumber ? settings[0].PhoneNumber : ''}</RouterNavLink>
                <RouterNavLink to="#" className="position-relative serv-cta-tel" onClick={handleShow}>Get Started</RouterNavLink>
                <RouterNavLink to="#" onClick={openLiveChat} className="serv-cta-chat chats position-relative text-white   ">
                  Live chat
                </RouterNavLink>
              </div>
            </div>
            <div className="col-md-6">
              <img src={ctaSideImgThree} alt="" className="img-fluid" />
            </div>
          </div>
        </div>
      </div>
      <div className="industry-section text-center py-5">
        <div className="container">
          <div className="text-center mb-4">
            <h2 className="fs_47 fw_400">Covering an extensive range of genres</h2>
          </div>
          <Nav className="justify-content-lg-center justify-content-start">
            {tabs.map((tab) => (
              <NavItem key={tab.id}>
                <NavLink
                  className='p-2'
                  active={activeTab === tab.id}
                  onClick={() => setActiveTab(tab.id)}
                >
                  <button>
                    {tab.title}
                  </button>
                </NavLink>
              </NavItem>
            ))}
          </Nav>
          <TabContent activeTab={activeTab}>
            {tabs.map((tab) => (
              <TabPane className={activeTab === tab.id ? 'active show' : ''} key={tab.id} tabId={tab.id}>
                {tab.content}
              </TabPane>
            ))}
          </TabContent>
        </div>
      </div>
      <div className="why-choose py-5">
        <div className="container">
          <div className="text-center mb-5">
            <h2 className="text-white fs_60 mb-3 fw_400">Why Choose Us?</h2>
          </div>
          <div className="row">
            <div className="col-12 mb-lg-0 mb-4 col-md-12 col-lg-4">
              <img src={WhyChooseSideImg} alt="" className="img-fluid" />
            </div>
            <div className="col-12 col-md-12 col-lg-8">
              <div className="row">
                <div className="col-md-6 mb-3">
                  <div className="card why-choose-box">
                    <div className="card-body">
                      <h2 className='fs_30 fw_400'>Budget-Friendly</h2>
                      <p className='fs-16 fw_400'>We help authors publish books at affordable rates. Your pockets will be left heavy even after our optimal publishing services.</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 mb-3">
                  <div className="card why-choose-box">
                    <div className="card-body">
                      <h2 className='fs_30 fw_400'>All Rights Ensured</h2>
                      <p className='fs-16 fw_400'>We understand publishing rights. Your intellectual property and private data, as well as book copyrights would be reserved for you.</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 mb-3">
                  <div className="card why-choose-box">
                    <div className="card-body">
                      <h2 className='fs_30 fw_400'>Research-Backed</h2>
                      <p className='fs-16 fw_400'>To legitimate your book&#39;s content, we review it meticulously. It is our responsibility that any author writing their book with us publishes accurate and trustworthy information.</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 mb-3">
                  <div className="card why-choose-box">
                    <div className="card-body">
                      <h2 className='fs_30 fw_400'>Lucrative Gains</h2>
                      <p className='fs-16 fw_400'>Our marketing strategies catalyze author revenues. We offer
                        distribution, marketing, and sales support to help you sell your book.</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 mb-3">
                  <div className="card why-choose-box">
                    <div className="card-body">
                      <h2 className='fs_30 fw_400'>Qualified Experts</h2>
                      <p className='fs-16 fw_400'>Our publishing staff is are degree or diploma holders. They boast a ton of experience and are devoted to helping you succeed.</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 mb-3">
                  <div className="card why-choose-box">
                    <div className="card-body">
                      <h2 className='fs_30 fw_400'>Time Management</h2>
                      <p className='fs-16 fw_400'>We prioritize timely deliveries. Our twice a week update protocol will keep you in loop with all the processes being undertaken on your book.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="theme-btns serv-cta-btn inr-tab-btn cta-tab-btn mt-5 flex-wrap justify-content-center d-flex gap-2">
            <RouterNavLink className='position-relative serv-cta-tel' to={`tel:${settings && settings[0] && settings[0].PhoneNumber ? settings[0].PhoneNumber : ''}`} ><FaPhoneAlt />{" "} {settings && settings[0] && settings[0].PhoneNumber ? settings[0].PhoneNumber : ''}</RouterNavLink>
            <RouterNavLink to="#" className="position-relative serv-cta-tel" onClick={handleShow}>Get Started</RouterNavLink>
            <RouterNavLink to="#" onClick={openLiveChat} className="serv-cta-chat chats position-relative text-white   ">
              Live chat
            </RouterNavLink>
          </div>
        </div>
      </div>
      <div className="bg__blue py-5">
        <div className="container">
          <div className="text-center mb-5">
            <h2 className="text-white fs_60 mb-3 fw_400">Our Principles</h2>
          </div>
          <div className="text-center mb-5">
            <h2 className="text-white fs_60 mb-3 fw_400">Expert, Versatile &amp; Passionate Writers</h2>
            <p className='text-white fs-16 fw_400'>BakeMyBook provides the best eBook writing services because of our professional book writers ranging across diverse academic backgrounds and genres who know how to get the best out of words. We write eBooks of all categories, including fiction, non-fiction, biographies, history, poetry, children books, fashion, autobiographies, and recipe books.</p>
          </div>
          <div className="row">
            <div className="col-md-4 mb-3">
              <div className="card why-choose-box">
                <div className="card-body">
                  <h2 className='fs_30 fw_400'>Every Author is Important</h2>
                  <p className='fs-16 fw_400'>At BakeMyBook, you hire eBook writers that are excited about your books. Being readers before writers, our carefully vetted writing teams cash on their readership experience to bring out the best in your books. That’s how we provide optimal creative writing services. We know how your idea can be manufactured into a successful bestseller book, and we stop short of nothing to help you achieve your dreams.</p>
                </div>
              </div>
            </div>
            <div className="col-md-4 mb-3">
              <div className="card why-choose-box">
                <div className="card-body">
                  <h2 className='fs_30 fw_400'>Every project is unique</h2>
                  <p className='fs-16 fw_400'>We are among the top ebook writing companies because we listen carefully to your requirements and tailor your eBook writing plan accordingly to bring the desired results. We never apply the same technique to different projects, we keep the originality. Our professional book writing services deal with every client individually, offering the most unique writing.</p>
                </div>
              </div>
            </div>
          </div>
          <div className="theme-btns serv-cta-btn inr-tab-btn cta-tab-btn mt-5 flex-wrap justify-content-center d-flex gap-2">
            <RouterNavLink className='position-relative serv-cta-tel' to={`tel:${settings && settings[0] && settings[0].PhoneNumber ? settings[0].PhoneNumber : ''}`} ><FaPhoneAlt />{" "} {settings && settings[0] && settings[0].PhoneNumber ? settings[0].PhoneNumber : ''}</RouterNavLink>
            <RouterNavLink to="#" className="position-relative serv-cta-tel" onClick={handleShow}>Get Started</RouterNavLink>
            <RouterNavLink to="#" onClick={openLiveChat} className="serv-cta-chat chats position-relative text-white   ">
              Live chat
            </RouterNavLink>
          </div>
        </div>
      </div>
      <div className="our-testimonial py-5 bg-detail spacing__x">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-lg-6 col-sm-12 text-center col-12 col-xs-12 col-md-12">
              <div className="img-box text-center d-flex justify-content-center align-items-center">
                <img alt="BakeMyBook" src={TestimonialImg} className="img-fluid" />
              </div>
            </div>
            <div className="col-lg-4 col-sm-12 col-12 col-xs-12 col-md-12">
              <div className="heading">
                <h6 className="best-heading-here">Testimonials</h6>
                <h3 className='fs_35 fw_700 text-white'>We are pleased with our consumer feedback.<span>.</span></h3>
                <p className='fs_16 fw_400 text-white'><i>We are a well-known name in the book writing industry, and we are pleased when our clients give us feedback.</i></p>
              </div>
              <Slider ref={sliderRef} {...sliderSettings} className="single-review-slider">
              <div className="single-review-box">
                  <img width="76" height="76" className='mb-2' alt="Phantom Writing" src={Client12} />
                  <p className='fs_16 fw_400 text-white'><i>"Great experience with Bake My Book.
                    <br />
                    Theo was my point of contact, and he did a great job managing me throughout the publishing process. I published my book at an extremely busy time in my life, so I needed some hand-holding. BMB did the entire process, from editing to helping me market and everything in between. Theo painstakingly answered all my questions, and in the few instances we went off track, he responded promptly and with good information. He also went the extra mile for me by helping me with Amazon. My book sales are doing well, and I appreciate all the support I received from Bake My Book."</i></p>
                  <h6 className='fs_14 text-captalize text-white'>Whitney Fleming</h6>
                </div>
                <div className="single-review-box">
                  <img width="76" height="76" className='mb-2' alt="Phantom Writing" src={Client11} />
                  <p className='fs_16 fw_400 text-white'><i>"Very professional service. Yes, you can self-publish these days, but navigating the process is complicated and time consuming. With BakeMyBook handling the logistics and offering valuable publishing insight, I can devote my time to writing. It's well worth the small investment. BakeMyBook's fees are more than fair and as the author I still retain 100% rights to my work, meaning I can still sell my book to a major publishing house in the future. When I complete my next work, I'll again call Theo and his team."</i></p>
                  <h6 className='fs_14 text-captalize text-white'>David Lutes</h6>
                </div>
                <div className="single-review-box">
                  <img width="76" height="76" className='mb-2' alt="Phantom Writing" src={Client8} />
                  <p className='fs_16 fw_400 text-white'><i>"I have been shopping for the right team to edit my books all have failed until I found bake my book. They are extremely personal and precise. They are genuinely interested and enthusiastic about your work and the editing is second to none. When they are finished your book will be flawless. I didn't think it was possible I will continue working with bake my book in the future."</i></p>
                  <h6 className='fs_14 text-captalize text-white'>Frank Roselli</h6>
                </div>
                <div className="single-review-box">
                  <img width="76" height="76" className='mb-2' alt="Phantom Writing" src={Client1} />
                  <p className='fs_16 fw_400 text-white'><i>"I just released my second book through BakeMyBook and I couldn't be happier. The experience has been genuinely positive. Theo answered my questions with patience, humor, and professionalism in a timely manner. The editing process was painless. The team made appropriate changes and suggestions without changing my voice. If you are considering self-publishing through BakeMyBook, I highly recommend them. They are able to help you every step of the way. I will submit my third book soon as well as any future projects.
                    Thank you so much for making me feel like one in a million and not just one out of many."</i></p>
                  <h6 className='fs_14 text-captalize text-white'>Patty Glaser</h6>
                </div>
                <div className="single-review-box">
                  <img width="76" height="76" className='mb-2' alt="Phantom Writing" src={Client14} />
                  <p className='fs_16 fw_400 text-white'><i>"I am glad I called bakemybook.com this summer.
                    Mr. Theo Madden was very professional, picking up the phone always and he answered all my questions. Also he gave me ideas how the whole process for publishing my book on Amazon would be. He helped me with everything I had difficulties and he understood what my book is all about. More over the whole process was done extremely professional and I will work with this company every time when I have a new book to publish.
                    I was talking to some other self publishing companies but they did not even come close to Bakemybook.com
                    They were only looking for how to make money and that I felt easily. Not explaining all details, not answering all my questions, being inpatient with me."</i></p>
                  <h6 className='fs_14 text-captalize text-white'>George Shumanov</h6>
                </div>
                <div className="single-review-box">
                  <img width="76" height="76" className='mb-2' alt="Phantom Writing" src={Client15} />
                  <p className='fs_16 fw_400 text-white'><i>"This company has done a great job for me…again! My second book using this company was published in December 2022. The team working with me showed a sincere interest in giving me an excellent book. I highly recommend Bake My Book."</i></p>
                  <h6 className='fs_14 text-captalize text-white'>Jenny Zimmer</h6>
                </div>
                <div className="single-review-box">
                  <img width="76" height="76" className='mb-2' alt="Phantom Writing" src={Client7} />
                  <p className='fs_16 fw_400 text-white'><i>"As a first-time author, I sought a publisher and editor who would direct me thought the process of actualizing my work into print. I sought sensitivity to my novella from an editor who could see beyond its initial flaws and refine its message, not view it merely as a vanity project.<br />
                    After a very disappointing and expensive false start with another company, I discovered Bake My Book. I received expert individual guidance, patient advice and encouragement from Theo Madden, the senior book publishing consultant at BMB. My many questions were answered promptly (and delivered with good humor). He steered the process of developing my book over the hurdles of publishing, making my novella a beautiful reality. His interest and enthusiasm for my project led to enlisting Francesca McCollough, the senior book editor. Her involvement taught me to write with precision and clarity, showing me ways to improve dialogue, plot pacing, and characterization. Her patience and incisive critique helped me become a confident writer. I became the fortunate recipient of a one-on-one tutorial in creative writing!
                    It is true that a book is judged by its cover. I submitted my ideas to the art team who translated my book into a beautiful cover that visually continues the story. It is exactly what I wanted. Working with the team at BMB has been a gratifying experience. Already, I have another project I will be publishing with this company.
                    Many thanks!"</i></p>
                  <h6 className='fs_14 text-captalize text-white'>Carol Funk</h6>
                </div>
                <div className="single-review-box">
                  <img width="76" height="76" className='mb-2' alt="Phantom Writing" src={Client13} />
                  <p className='fs_16 fw_400 text-white'><i>"I had a wonderful experience with BakeMyBook, fantastic experience actually, from the moment I called in, spoke with Theo, he was very calm and knowledgeable about what I needed, that wiped away all those anxiety and worries I was having due to my previous encounters with other publishers. My experience has been wonderful, Theo and his team held my hand throughout, from book editing, formatting, book cover design to service and delivery. I was kept informed of the progress and process, their feedback, customer service impeccable. I will certainly recommend BakeMyBook and I intend using their services for my upcoming publishing needs. Thank you so much for going the extra mile with the publication of my novel , Things unsaid "memoirs of an empty nester".
                    BakeMyBook -They are simply the BEST."</i></p>
                  <h6 className='fs_14 text-captalize text-white'>Dorothy Okung</h6>
                </div>
                <div className="single-review-box">
                  <img width="76" height="76" className='mb-2' alt="Phantom Writing" src={Client5} />
                  <p className='fs_16 fw_400 text-white'><i>“Theo Madden was very helpful with the creativity of my book, it was a great experience overall.”</i></p>
                  <h6 className='fs_14 text-captalize text-white'>Tracy Hudson</h6>
                </div>
              </Slider>
              <div className="text-end">
                <button className="btn bg-white border-0 rounded-pill" onClick={next}>
                  Next <FaChevronRight className='text-success' />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="packages-section py-5 bg__black">
        <div className="container">
          <div className="row mb-3 align-items-center">
            <div className="col mb-lg-0 mb-4 content text-lg-start text-center">
              <h2 className="fs_44 fw_400 text-white lh_1 mb-4">Why wait? Call us today to get your
                book published in the best manner.</h2>
              <p className="fs_20 mb-3 fw_400 text-white">BakeMyBook helps book enthusiasts achieve their dreams. We bring colors to
                your thoughts and expressions. From book publishing to book marketing, we
                offer all kinds of services:</p>
              <ul className="list-unstyled checklist row">
                <li className="col-md-4 col-12 text-start pb-1 text-white"><FaCheckCircle className='text-success' /> Ghostwriting</li>
                <li className="col-md-4 col-12 text-start pb-1 text-white"><FaCheckCircle className='text-success' /> Novel writing</li>
                <li className="col-md-4 col-12 text-start pb-1 text-white"><FaCheckCircle className='text-success' /> Scriptwriting</li>
                <li className="col-md-4 col-12 text-start pb-1 text-white"><FaCheckCircle className='text-success' /> Book layout, typesetting &amp; formatting</li>
                <li className="col-md-4 col-12 text-start pb-1 text-white"><FaCheckCircle className='text-success' /> Book publishing</li>
                <li className="col-md-4 col-12 text-start pb-1 text-white"><FaCheckCircle className='text-success' /> Cover design </li>
                <li className="col-md-4 col-12 text-start pb-1 text-white"><FaCheckCircle className='text-success' /> Book editing</li>
                <li className="col-md-4 col-12 text-start pb-1 text-white"><FaCheckCircle className='text-success' /> Book illustration</li>
                <li className="col-md-4 col-12 text-start pb-1 text-white"><FaCheckCircle className='text-success' /> Children books</li>
                <li className="col-md-4 col-12 text-start pb-1 text-white"><FaCheckCircle className='text-success' /> Recipe books</li>
                <li className="col-md-4 col-12 text-start pb-1 text-white"><FaCheckCircle className='text-success' /> Biography</li>
                <li className="col-md-4 col-12 text-start pb-1 text-white"><FaCheckCircle className='text-success' /> Book printing</li>
                <li className="col-md-4 col-12 text-start pb-1 text-white"><FaCheckCircle className='text-success' /> Book marketing</li>
                <li className="col-md-4 col-12 text-start pb-1 text-white"><FaCheckCircle className='text-success' /> Audiobooks</li>
              </ul>
              <img src={BookPublishingLogos} alt="" className="img-fluid" />
            </div>
            <div className="col-md-4">
              <div className="banner__form card border-0">
                <div className="card-body">
                  <h4 className="mb-2 fs_36 fw_700 text__shadow text__blue text-center">Sign up to AVAIL
                  </h4>
                  <span className="d-block bg__blue mb-2 text-center fs_16 text-white rounded py-2">50% Discount</span>
                  <p className='fs_18 fw_700 text__shadow text__blue text-center mb-4'>Discuss Your Project With Our Experts</p>
                  <AllPageContactForm PageNameTracking="[EBook Writing Page Contact Form]" />
                </div>
              </div>
            </div>
          </div>
          <p className="fs_20 mb-3 fw_400 text-white text-center">Enjoy a one-stop shopping experience at BakeMyBook as we solve all your book writing and publishing needs. Give the professional look to your manuscript that it deserves. Choose from our wide range of self-published book marketing service and get started today!</p>
        </div>
      </div>
      <div className="cta-section why-choose py-5">
        <div className="container">
          <div className="text-center mb-3">
            <h2 className="text-white fs_47 fw_400">Drop Us A Line</h2>
            <p className="text-white fs_16 fw_400 mt-3">Fill out your details below and we’ll get back to you shortly.</p>
            <h2 className="text-white fs_40 fw_400">Discuss Your Project</h2>
          </div>
          <div className="theme-btns serv-cta-btn inr-tab-btn cta-tab-btn mt-2 d-flex justify-content-center gap-2">
            <RouterNavLink className='position-relative serv-cta-tel' to={`tel:${settings && settings[0] && settings[0].PhoneNumber ? settings[0].PhoneNumber : ''}`} ><FaPhoneAlt />{" "} {settings && settings[0] && settings[0].PhoneNumber ? settings[0].PhoneNumber : ''}</RouterNavLink>
            <RouterNavLink to="#" className="position-relative serv-cta-tel" onClick={handleShow}>Get Started</RouterNavLink>
            <RouterNavLink to="#" onClick={openLiveChat} className="serv-cta-chat chats position-relative text-white   ">
              Live chat
            </RouterNavLink>
          </div>
        </div>
      </div>
    </>
  )
}

export default EBookWriting 